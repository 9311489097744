if(window.location.pathname.indexOf('tempo-de-entrega' > -1)) {
    const state = document.getElementById('state');
    const city = document.getElementById('city');
    const shippingTime = document.getElementById('shipping-time');
    const estimatedTotal = document.getElementById('estimated-total');
    const cityName = document.getElementById('city-name');

    const inputCidades = document.querySelector('input[name="lista-cidades"]');
    const listaCidades = JSON.parse(inputCidades.value);


    state.addEventListener('change', function() {
        for(let i = city.length; i > 0; i--) {
            city.remove(i);
        }

        const selectedStateCities = listaCidades.filter((cidade) => {
            return cidade.state == state.value ? cidade : '';
        });
        
        selectedStateCities.forEach(cidade => {
            const newOption = document.createElement('option');
            newOption.text = cidade.city;
            newOption.value = cidade.lead_time;
            city.add(newOption);
        });
    });

    city.addEventListener('change', function() {
        if(city.options[city.selectedIndex].text != 'Selecione sua cidade') {
            shippingTime.innerHTML = city.value;
            estimatedTotal.innerHTML = Number(city.value) + 4;
            cityName.innerHTML = `em ${city.options[city.selectedIndex].text}`;
        }
    });
}